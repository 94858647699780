export default {
  required: 'Required',
  wrong_format: 'format must be: +40XXXXXXXXX.',
  unique: 'Unique',
  required_only_one_type: 'You can select categories only from a type',
  not_found: 'Not found',
  invalid: 'Invalid',
  string_too_long: 'String too long',
  string_too_short: 'String too short',
  special_characters_not_allowed: 'Special characters not allowed',
  string_empty: 'String empty',
  not_numeric: 'Not numeric',
  not_percent_interval: 'not in percent interval',
  not_between_0_100: 'not in percent interval 0-100%',
  amount_not_in_range: 'You have exceeded the allowed amount for this benefit',
  invalid_minimum: 'Invalid minimum',
  invalid_maximum: 'Invalid maximum',
  working_days_consumed: 'consumed',
  working_days_cannot_be_less_than_consumed_days: 'working_days_cannot_be_less_than_consumed_days',
  duplicate_cnp: 'Duplicated cnp',
  duplicate: 'Duplicate',
  cnp_exists: 'Cnp exists',
  employee_pending_delete: 'Employee pending delete',
  minimum_date: 'minimum date',
  image_type_supported: "You have uploaded an invalid image file extension. Extension accepted png, jpg and jpeg",
  file_path_invalid: "File was not found. Either path is wrong or file is not present where you want it to be",
  already_used: "already used",
  invalid_format: 'invalid format',
  image_square: "must have a width equal to the length",
  image_100_100: "must have 100px x 100px minim",
  image_500_500: "must have 500px x 500px minim",
  image_1018_166: "must have 1018px x 166px minim",
  invalid_length: 'invalid length',
  existing: 'existing',
  unavailable: 'unavailable',
  exceeded: 'exceeded',
  inactive: 'inactive',
  before_date: 'before date',
  after_date: 'after date',
  too_high: 'too high',
  too_low: 'too low',
  untouched: 'untouched',
  minimum_1_required: 'at least 1',
  not_exceeds_minimum: 'does not exceed minimum value',
  select_client: 'Select client',
  insufficient: 'Insufficient',
  exceeds_budget: 'exceeded',
  exceeds_maximum: 'provided value exceeds the maximum legal value',
  not_multiple_of_ticket_value: 'provided value must be multiple of 10',
  select_at_least_one_request: 'select at least one request',
  could_no_be_processed: 'could not be processed',
  less_than_previous_month: 'less than previous import month',
  less_than_current_month: 'less than current import month',
  exceeded_yearly_order_placement: 'last day for placing orders exceeded',
  exceeded_last_topup_date: 'The last day for placing orders set by your employer has been passed',
  exceeded_last_topup_date_client: 'The last day of the month selected by your employer for placing orders has been exceeded',
  benefit_value_must_be_greater_than_0: 'value must be greater than 0',
  no_time_sheet: 'no timesheet uploaded',
  consumed: 'consumed',
  not_in_range: 'not in range',
  over_range: 'permissible limit has been exceeded',
  pending_approval: 'awaiting approval',
  beneficiary_does_not_have_card: 'The beneficiary does not have an active card for the selected benefit',
  exceeding_max_working_days_month: 'You have exceeded the maximum working days for this month ({days_nr})',
  event_exceeds_date: 'more then 30 days have passed since the event date',
  event_exceeds_birth_date: 'more then 30 days have passed since the event date',
  not_has_access_benefit: 'you dont have access to this benefit',
  invalid_json: 'invalid JSON format',
  exceeds_5_mb: 'exceeds 5MB file size',
  exceeds_10_mb: 'exceeds 10MB file size',
  exists: 'already used',
  not_has_access_benefit_up_dejun: 'does not have access to Up Dejun for dinner tickets card topup.',
  month_last_top_up_date_exceeded: 'last topup date for month was exceeded making month timesheet edit locked',
  exceeds_maximum_parent: 'provided value exceeds the maximum value for parent',
  active_auto_for_another: 'auto topup is already active for another benefit',
  non_alpha_num: 'should contain only letters and numbers',
  string_exceeds_27: 'exceeds 27 characters (including a separating space)',
  update_status_invalid: 'voucher status forbids update',
  crm_code_not_found: 'no active benefit with allocated partner found',
  auto_topup_not_legislated_not_active: 'is not active',
  order_exists_month: 'already exists in last month for Company Employee',
  invalid_over: ' total value exceeds the allocated budget',
  not_allowed_contribution_type: "Tip de contribu?ie nepermis",
  at_list_1_character: 'Please enter at least 1 character.',
  invalid_phone_number_start: 'Phone number must start with 0',
  voucher_invoices_errors: {
    error_purchase_invoice_number_required: 'The purchase invoice number is required.',
    error_purchase_invoice_number_invalid_type: 'The purchase invoice number must be a string.',
    error_purchase_invoice_number_max_length: 'The purchase invoice number cannot exceed 255 characters.',
    error_code_required: 'The voucher code is required.',
    error_code_invalid_type: 'The voucher code must be a string.',
    error_code_max_length: 'The voucher code cannot exceed 255 characters.',
    error_voucher_value_required: 'The voucher value is required.',
    error_voucher_value_invalid_type: 'The voucher value must be numeric.',
    error_up_purchase_date_required: 'The purchase date is required.',
    error_up_purchase_date_invalid_format: 'The purchase date format is invalid.',
    error_partner_cui_required: 'The partner CUI is required.',
    error_partner_cui_invalid: 'The partner CUI is invalid or does not exist.',
    error_invoice_value_with_discount_invalid_type: 'The invoice value with discount must be numeric.',
    error_up_ro_invoice_value_with_discount_invalid_type: 'The Up discount invoice value must be numeric.',
    error_invoice_value_without_discount_invalid_type: 'The invoice value without discount must be numeric.',
    error_vat_percentage_invalid_type: 'The VAT percentage must be numeric.',
    error_discount_value_invalid_type: 'The discount value must be numeric.',
    error_discount_percentage_invalid_type: 'The discount percentage must be numeric.',
    error_comments_invalid_type: 'The comments must be a string.',
    error_comments_max_length: 'The comments cannot exceed 255 characters.',
    duplicate_in_file: 'The voucher code is duplicated in the file.',
    duplicate_in_db: 'The voucher code is duplicated in the database.',
  }
}
