export default {
  titles: {
    list: 'Stocuri vouchere',
    invoices: 'Facturi',
    import: 'Import',
    details: 'Detalii',
    import_summary: 'Rezumat import',
    import_items: 'Intrari import',
    import_item_details: 'Detalii voucher',
    import_complete: 'Import finalizat cu success!',
    voucher_stocks: 'Stocuri vouchere',
    voucher_invoices: 'Facturi vouchere',
  },
  fields: {
    benefit: 'Beneficiu',
    partner: 'Partener',
    benefit_crm_code: 'Cod CRM',
    code: 'Cod voucher',
    start_date: 'Valabilitate start',
    end_date: 'Data expirare',
    expiration_date: 'Data expirare',
    terms: 'Termeni si conditii',
    instructions: 'Instructiuni utilizare',
    campaign_terms: 'Termeni & conditii campanie',
    pin_code: 'Cod pin',
    subscription_period: 'Perioada abonament',
    status: 'Stare',
    comments: 'Mentiuni',
    created_at: 'Data adaugarii',
    placed_at: 'Data comenzii',
    reconciled: 'Reconciliat'

  },
  invoicesFields :{
    partner: 'Partener',
    partner_cui: 'CUI partener',
    code: 'Cod voucher',
    voucher_value: 'Val voucher',
    up_purchase_date: 'Data achizitiei voucher',
    invoice_number: 'Nr. Factura',
    invoice_value_without_discount : 'Val. factura fara discount',
    discount_value: 'Val. discount',
    invoice_value_with_discount : 'Val. factura cu discount',
    up_ro_invoice_value_with_discount : 'Val. factura de discount emisa de Up',
    discount_percentage: 'Discount %',
    vat_percentage: 'TVA %',
    comments: 'Comments',
    reconciled: 'Reconciliat',
    reconciliation_status: "Status reconciliare",
    filterDate: 'Filtrare data',
  },
  invoicesModalFields: {
    modalTitle: "Detalii vouchere {partner} din factura {invoice_number}",
    invoice_reconciled: 'Reconciliata',
    header_title: 'Factura cu numarul { invoice_number} de la { partner }',
    invoice_details: 'Detalii factura',
    vouchers: "Vouchere",
    deleteInvoiceConfirmation: "Sigur doriti sa stergeti factura: <strong>{invoiceNumber}</strong> de la partenerul: <strong>{partnerName}</strong> ?",
  },
  inputLabels: {
    startCreatedAt: 'Start data creare',
    endCreatedAt: 'End data creare',
    startPlacedAt: 'Start data plasare comanda',
    endPlacedAt: 'End data plasare comanda',
    startDate: 'Data start',
    endDate: 'Data sfarsit',

  },
  btns: {
    import_example: 'Exemplu import',
    import_start: 'Incepe import',
    import_restart: 'Incepe alt import',
    back_to_list: 'Lista de vouchere',
    back_to_list_invoice: 'Lista de facturi',
  },
  summary: {
    in_stock: 'In stoc',
    will_expiring: 'Expira',
    reserved: 'Rezervat',
    used: 'Folosit',
    expired: 'Expirat',
  },
  statuses: {
    0: 'Toate',
    1: 'In stoc',
    2: 'Rezervat',
    3: 'Folosit',
    4: 'Expirat',
    5: 'Va expira',
  },
  reconciliation_status:{
    5: 'Toate',
    1: 'Reconciliat',
    2: 'Nereconciliat'
  },
  import: {
    invalid_reasons: {
      malformed_import_file: 'Fisierul incarcat nu respecta structura',
      import_empty: 'Importul este gol',
    },
    statuses: {
      5: 'Toate',
      1: 'Valid',
      2: 'Invalid',
      3: 'Modificare',
    },
    summary: {
      total: 'Total vouchere',
      new_count: 'Numar intrari noi',
      error_count: '',
      total_update: 'Numar intrari modificate',
    },
    messages: {
      has_invalid_items: 'Importul are vouchere invalide!'
    }
  }
}
